<template>
  <div></div>
</template>
<script>
export default {
  // this is as separate component which ensures that user is signed in and settings was already fetched
  // (unlike having this in router)
  name: 'Welcome',
  mounted() {
    const welcomeId = this.$store.getters['welcomeEventId'];

    if (welcomeId) {
      this.$router.replace({
        name: 'meeting',
        params: { id: welcomeId, calendarId: 'primary' },
      });
    } else {
      this.$router.replace({ name: 'meetings' });
    }
  },
};
</script>
